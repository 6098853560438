export default {
    "AdditionalUsers.four_plus": "{first}, {second} i jeszcze {number}",
    "AdditionalUsers.plus_more": "+ jeszcze {number}",
    "AdditionalUsers.three": "{first}, {second} i {third}",
    "AdditionalUsers.two": "{first} i {second}",
    "AppointmentCancelled.email_message": "Wysłaliśmy Ci wiadomość e-mail potwierdzającą anulowanie.",
    "AppointmentCancelled.this_title": "To spotkanie zostało anulowane",
    "AppointmentCancelled.your_title": "Twoje spotkanie zostało anulowane",
    "AppointmentDetails.appointment_details": "Szczegóły spotkania",
    "AppointmentDetails.appointment_duration": "{start}–{end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "Uczestnik",
    "AppointmentDetails.attendees": "Uczestnicy",
    "AppointmentDetails.confirmation_number": "Numer potwierdzenia",
    "AppointmentDetails.date_time": "Data i godzina",
    "AppointmentDetails.join_by_phone": "Dołącz przez telefon: <a>{number}<\/a>",
    "AppointmentDetails.join_call": "Dołącz do rozmowy",
    "AppointmentDetails.join_the_call": "Dołącz za pomocą podanego linku",
    "AppointmentDetails.join_with_this_link": "Dołącz za pomocą tego linku",
    "AppointmentDetails.location": "Lokalizacja",
    "AppointmentDetails.phone_call": "Rozmowa telefoniczna",
    "AppointmentDetails.phone_number_ending_in": "Numer telefonu kończący się na {number}",
    "AppointmentDetails.pin": "Kod PIN: {pin}",
    "AppointmentDetails.service": "Usługa",
    "AppointmentDetails.user": "Personel",
    "AppointmentDetails.user_preference": "Preferencje dotyczące personelu",
    "AppointmentDetails.video_call": "Wideorozmowa",
    "AppointmentDetails.we_will_call": "Zadzwonimy do Ciebie pod podany przez Ciebie numer",
    "AppointmentDetails.we_will_call_you": "Zadzwonimy do Ciebie pod podany przez Ciebie numer",
    "AppointmentDetails.we_will_send_video_info": "Wyślemy Ci informacje o tym, jak dołączyć do spotkania",
    "AppointmentSummary.cancel": "Anuluj",
    "AppointmentSummary.default_error_title": "Wystąpił błąd",
    "AppointmentSummary.location_button": "Zobacz lokalizacje",
    "AppointmentSummary.location_unavailable_description": "Ta lokalizacja nie jest już dostępna. W celu zarezerwowania terminu musisz wybrać inną lokalizację.",
    "AppointmentSummary.location_unavailable_title": "Lokalizacja nie jest już dostępna",
    "AppointmentSummary.modal_title": "Tytuł",
    "AppointmentSummary.service_button": "Zobacz usługi",
    "AppointmentSummary.service_unavailable_description": "Ta usługa nie jest już dostępna. Sprawdź, czy inne usługi odpowiadają Twoim potrzebom lub skontaktuj się z nami pod numerem {phone}.",
    "AppointmentSummary.service_unavailable_title": "Usługa nie jest już dostępna",
    "AppointmentSummary.times_button": "Znajdź termin",
    "AppointmentSummary.times_unavailable_description": "Termin {time} nie jest już dostępny w dniu {date}. W celu zarezerwowania terminu spotkania musisz wybrać inną godzinę.",
    "AppointmentSummary.times_unavailable_title": "Termin nie jest już dostępny",
    "AppointmentSummaryExpansionPanel.title": "Podsumowanie spotkania",
    "AttendeeListItem.delete": "Usuń dodatkowego uczestnika",
    "AttendeeListItem.deleted": "Usunięto {name}",
    "AttendeeListItem.edit": "Zaktualizuj dodatkowego uczestnika",
    "BackButton.back": "Wróć",
    "BackButton.back_results": "Wróć do wyników",
    "BookingValidationErrorModal.default_error_title": "Wystąpił błąd",
    "BookingValidationErrorModal.location_button": "Zobacz lokalizacje",
    "BookingValidationErrorModal.location_unavailable_description": "Ta lokalizacja nie jest już dostępna. W celu zarezerwowania terminu musisz wybrać inną lokalizację.",
    "BookingValidationErrorModal.location_unavailable_title": "Lokalizacja nie jest już dostępna",
    "BookingValidationErrorModal.modal_title": "Tytuł",
    "BookingValidationErrorModal.service_button": "Zobacz usługi",
    "BookingValidationErrorModal.service_unavailable_description": "Ta usługa nie jest już dostępna. Sprawdź, czy inne usługi odpowiadają Twoim potrzebom lub skontaktuj się z nami pod numerem {phone}.",
    "BookingValidationErrorModal.service_unavailable_title": "Usługa nie jest już dostępna",
    "BookingValidationErrorModal.times_button": "Znajdź termin",
    "BookingValidationErrorModal.times_unavailable_description": "Termin {time} nie jest już dostępny w dniu {date}. W celu zarezerwowania terminu spotkania musisz wybrać inną godzinę.",
    "BookingValidationErrorModal.times_unavailable_title": "Termin nie jest już dostępny",
    "CancelModal.additional_details": "Dodatkowe szczegóły",
    "CancelModal.appointment_date": "{date} o {time}",
    "CancelModal.cancel_appointment": "Anuluj spotkanie",
    "CancelModal.keep": "Zachowaj",
    "CancelModal.message": "Nie można cofnąć anulowania spotkania, które zostało zaplanowane na {date}.",
    "CancelModal.title": "Anuluj spotkanie",
    "CancelModal.why_cancel_appointment": "Dlaczego to spotkanie zostało anulowane?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "Tego spotkania nie można już anulować.",
    "ChangesToYourAppointment.cancellation_policy_header": "Zasady anulowania",
    "ChangesToYourAppointment.cannot_cancel": "Tego spotkania nie można anulować.",
    "ChangesToYourAppointment.cannot_reschedule": "Nie można zmienić terminu tego spotkania.",
    "ChangesToYourAppointment.loading": "Ładowanie...",
    "ChangesToYourAppointment.meeting_method_subtitle": "Jeśli chcesz zmienić sposób, w jaki chcesz uczestniczyć w spotkaniu, zaktualizuj metodę spotkania.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "Jeśli chcesz zmienić sposób, w jaki chcesz uczestniczyć w spotkaniu, możesz to zrobić na {duration} przed zaplanowanym terminem.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "Nie można zmienić metody spotkania",
    "ChangesToYourAppointment.no_meeting_method_options": "Żadne inne opcje nie są dostępne",
    "ChangesToYourAppointment.reschedule_appointment": "Zmień termin spotkania",
    "ChangesToYourAppointment.reschedule_header": "Zmień termin",
    "ChangesToYourAppointment.reschedule_no_longer_available": "Terminu tego spotkania nie można już zmienić.",
    "ChangesToYourAppointment.reschedule_policy_details": "Jeśli chcesz zmienić termin tego spotkania, możesz to zrobić na {duration} przed zaplanowanym terminem.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "Jeśli chcesz zmienić termin tego spotkania, możesz to zrobić przed zaplanowanym terminem.",
    "ChangesToYourAppointment.title": "Zmiany dotyczące spotkania",
    "ChangesToYourAppointment.update_meeting_method": "Zaktualizuj metodę spotkania",
    "ChangesToYourAppointment.update_meeting_method_not_available": "Tej metody spotkania nie można już zmienić.",
    "CheckIn.check_in": "Zameldować się",
    "CheckIn.check_in.aria_label": "Sprawdź swoją wizytę",
    "CheckIn.confirmation.subtitle": "Wkrótce skontaktujemy się z Tobą.",
    "CheckIn.confirmation.subtitle_notifiable": "Wkrótce skontaktujemy się z Tobą. Powiadomimy Cię, gdy przyjdzie Twoja kolej",
    "CheckIn.confirmation.title": "Zameldowano się!",
    "CheckIn.email": "Adres e-mail",
    "CheckIn.fetch_failed": "Przepraszamy, ale wystąpił błąd podczas wyszukiwania terminu. Odśwież stronę i spróbuj ponownie.",
    "CheckIn.join_line": "DOŁĄCZ DO LINII",
    "CheckIn.join_line.aria_label": "Kliknij tutaj, aby dołączyć do kolejki zamiast umawiać się na wizytę",
    "CheckIn.join_line.title": "Nie masz terminu?",
    "CheckIn.landing_page.title": "Sprawdź swoje spotkanie",
    "CheckIn.next": "Następny",
    "CheckIn.next.aria_label": "Sprawdź swoje spotkanie",
    "CheckIn.not_found.email": "Przepraszamy, ale nie znaleźliśmy nadchodzącego spotkania, które odpowiadałoby podanemu adresowi e-mail. Należy pamiętać, że na spotkania można się rejestrować tylko w ciągu 30 minut od zaplanowanej godziny rozpoczęcia.",
    "CheckIn.not_found.phone": "Przepraszamy, ale nie znaleźliśmy nadchodzącego spotkania, które odpowiadałoby podanemu numerowi telefonu. Należy pamiętać, że na spotkania można się rejestrować tylko w ciągu 30 minut od zaplanowanej godziny rozpoczęcia.",
    "CheckIn.phone": "Numer telefonu",
    "CheckIn.preference": "Używając {preference} aby wyszukać spotkanie",
    "CheckIn.subtitle": "Użyj swojego adresu e-mail lub numeru telefonu, aby wyszukać termin",
    "CheckIn.subtitle_email_only": "Użyj swojego adresu e-mail, aby wyszukać termin",
    "CheckIn.summary.fetch_failed": "Przepraszamy, ale wystąpił błąd podczas sprawdzania terminu. Odśwież stronę i spróbuj ponownie.",
    "CheckIn.summary.sms_updates": "Otrzymuj aktualizacje wiadomości tekstowych",
    "CheckIn.summary.sms_updates.dialog": "Otrzymywać aktualizacje SMS-ów?",
    "CheckIn.summary.sms_updates.dialog.accept": "Tak, wysyłaj mi aktualizacje w wiadomościach SMS",
    "CheckIn.summary.sms_updates.dialog.deny": "Nie, zamelduj się bez otrzymywania aktualizacji",
    "CheckIn.summary.sms_updates.message": "Wyślemy Ci informacje o Twojej kolejce na {phone}",
    "CheckIn.summary.subtitle": "Potwierdź szczegóły spotkania poniżej i zamelduj się",
    "CheckIn.summary.title": "Potwierdź i zamelduj się",
    "CheckIn.title": "Sprawdź swoje spotkanie",
    "CircularProgress.loading": "Ładowanie",
    "Common.appointment_details": "Szczegóły terminu",
    "CookieNotice.accept": "Akceptuję",
    "CookieNotice.closed": "Zastrzeżenie dotyczące plików cookie zostało zamknięte.",
    "CookieNotice.ignore": "Ignorować",
    "CookieNotice.learn_more": "Dowiedz się więcej",
    "CookieNotice.message": "Używamy plików cookie, aby zapewnić Ci jak najlepsze wrażenia z użytkowania naszej witryny internetowej.",
    "CookieNotice.title": "Zastrzeżenie dotyczące plików cookie",
    "CountrySelectInput.label": "Kraj",
    "CountrySelectInput.most_popular": "Najpopularniejsze",
    "CountryTimezoneModal.description": "Wszystkie terminy będą wyświetlane zgodnie z wybraną poniżej strefą czasową.",
    "CountryTimezoneModal.title": "Wybierz strefę czasową",
    "DateTime.locale_badge_label": "{name} mówi w języku {language}",
    "DateTime.locale_badge_tooltip": "Mówi w języku {language}",
    "DateTime.mobile_header": "Kiedy chcesz odbyć spotkanie?",
    "DateTime.no_language_preference": " (nie {locale})",
    "DateTime.no_times_available_on_date": "Brak dostępnych terminów",
    "DateTime.select_time": "Wybierz godzinę",
    "DateTime.select_user": "Wybierz członka personelu",
    "DateTime.showing_times_for": "Wyświetlane są godziny dla",
    "DateTime.times_available_for_people_speaking": "Dostępne terminy dla {locale} w dniu {date}",
    "DateTime.times_available_on_date": "Dostępne terminy w dniu {date}",
    "DateTime.timezone_header": "Godziny są podawane w {timezone}",
    "DateTime.user_mobile_header": "Kiedy chcesz się spotkać z {name}?",
    "DateTime.user_schedule": "Zaplanuj termin spotkania z {name}",
    "DateTime.who_speaks": " osobą mówiącą {locale}",
    "Dates.all_day_date": "{date}, cały dzień",
    "Dates.all_day_today": "Dzisiaj, cały dzień",
    "Dates.plural.day": "{count, plural, one {dzień} other {dni}}",
    "Dates.plural.month": "{count, plural, one {miesiąc} other {miesiące}}",
    "Dates.plural.week": "{count, plural, one {tydzień} other {tygodnie}}",
    "Dates.plural.year": "{count, plural, one { rok } other { lata }}",
    "Dates.range_today": "Dzisiaj, {start}–{end}",
    "Dates.range_today_with_timezone": "Dzisiaj, {start}–{end} {timezone}",
    "Dates.today_with_timezone": "Dzisiaj, {date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "Mówi w języku {language}",
    "Details.form_header": "Wprowadź swoje szczegóły",
    "DetailsForm.add": "Dodaj",
    "DetailsForm.add_attendee": "+ Dodaj dodatkowego uczestnika",
    "DetailsForm.add_attendee_title": "Dodaj uczestnika",
    "DetailsForm.additional_attendees": "Dodatkowi uczestnicy",
    "DetailsForm.additional_attendees_limit": "(maks. {limit})",
    "DetailsForm.address": "Adres",
    "DetailsForm.agree_tos": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorTerms} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorTerms} i {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Rezerwując spotkanie, akceptujesz {vendorTerms} i {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Rezerwując spotkanie, akceptujesz {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Rezerwując spotkanie, akceptujesz {vendorTerms} dostawcy {vendor}.",
    "DetailsForm.appointment_policy_action": "rezerwacja terminu",
    "DetailsForm.cell_phone": "Numer telefonu komórkowego",
    "DetailsForm.cell_phone_context": "Zadzwonimy do Ciebie pod ten numer",
    "DetailsForm.city": "Miasto",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "Kraj",
    "DetailsForm.duplicate_attendee": "Uczestnik o takiej nazwie i adresie e-mail już istnieje",
    "DetailsForm.edit": "Zapisz",
    "DetailsForm.edit_attendee_title": "Edytuj uczestnika",
    "DetailsForm.email": "E-mail",
    "DetailsForm.explicit_compliance_agreement_label": "Akceptuję",
    "DetailsForm.first_name": "Imię",
    "DetailsForm.home_phone": "Numer telefonu domowego",
    "DetailsForm.last_name": "Nazwisko",
    "DetailsForm.notes": "Szczegóły terminu",
    "DetailsForm.postal_code": "Kod pocztowy",
    "DetailsForm.primary_phone_context": "Zadzwonimy do Ciebie pod ten numer",
    "DetailsForm.privacy": "informacja o ochronie prywatności",
    "DetailsForm.province": "Prowincja",
    "DetailsForm.receive_sms": "Otrzymuj powiadomienia o spotkaniach w wiadomościach tekstowych",
    "DetailsForm.receive_sms_required": "Wykorzystamy te informacje, aby wysyłać Ci ważne informacje dotyczące Twojego spotkania",
    "DetailsForm.select_country": "Wybierz kraj",
    "DetailsForm.select_province": "Wybierz prowincję",
    "DetailsForm.select_state": "Wybierz stan",
    "DetailsForm.sms_error_title": "Ponownie włącz otrzymywanie wiadomości tekstowych.",
    "DetailsForm.state": "Stan",
    "DetailsForm.submit": "Kontynuuj",
    "DetailsForm.terms_of_use": "warunki świadczenia usług",
    "DetailsForm.walkin_policy_action": "dołączenie do linii",
    "DetailsForm.work_phone": "Numer telefonu służbowego",
    "Dialog.clear": "Wyczyść",
    "Dialog.confirm": "Potwierdź",
    "Dropzone.delete": "Usuń",
    "Dropzone.instructions": "Przeciągnij pliki tutaj lub <span>przeglądaj, aby go przesłać<\/span>",
    "Duration.days": "{duration, plural, one {# dzień} other {# dni}}",
    "Duration.hours": "{duration, plural, one {# godz.} other {# godz.}}",
    "Duration.minutes": "{duration, plural, one {# min} other {# min}}",
    "Duration.weeks": "{duration, plural, one {# tydzień} other {# tyg.}}",
    "EmptyState.contact_vendor": "Spróbuj ponownie lub skontaktuj się z nami",
    "FastRoute.book_anyway": "Zamiast tego zarezerwuj termin spotkania",
    "FastRoute.skip_the_wait": "Pomiń oczekiwanie",
    "FastRoute.title": "Termin spotkania nie jest wymagany",
    "Feedback.already_submitted": "Opinia została już przesłana, dziękujemy!",
    "Feedback.appointment_review_subtitle": "Powiedz nam, jak się czułeś po spotkaniu.",
    "Feedback.appointment_review_title": "Opinia o spotkaniu",
    "Feedback.close": "Zamknij sekcję opinii",
    "Feedback.error": "Przed przesłaniem wystaw ocenę",
    "Feedback.experience": "Twoje doświadczenie",
    "Feedback.experience_error_message": "Opinia jest polem wymaganym. Upewnij się, że długość jest większa niż 144 znaki",
    "Feedback.experience_required": "Twoje doświadczenie (wymagane)",
    "Feedback.google_description": "Bardzo cenimy sobie Twoją opinię i wierzymy, że skorzystają z niej inni nasi klienci. Jeśli masz chwilę, aby poinformować o swoich doświadczeniach z nami za pośrednictwem recenzji Google, kliknij poniższy przycisk, a my Cię przekierujemy.",
    "Feedback.google_submit": "Prześlij recenzję Google",
    "Feedback.neutral": "Neutralna",
    "Feedback.new_title": "Opinia",
    "Feedback.rating": "Ocena (wymagana)",
    "Feedback.response_prompt": "Powiedz nam, pod jakim względem Twoje doświadczenie mogłoby być lepsze",
    "Feedback.send": "Wyślij opinię",
    "Feedback.sending": "Wysyłanie opinii",
    "Feedback.staff_review_subtitle": "Powiedz nam, jak spisał się dzisiaj nasz pracownik.",
    "Feedback.staff_review_title": "Informacje zwrotne od personelu",
    "Feedback.subtitle": "Twoja opinia jest anonimowa. Wykorzystamy te informacje, aby poprawić doświadczenia z rezerwacji.",
    "Feedback.success": "Opinia została wysłana, dziękujemy!",
    "Feedback.thank_you": "Dziękujemy za opinię!",
    "Feedback.title": "Jakie było Twoje doświadczenie związane z rezerwacją spotkania?",
    "Feedback.very_dissatisfied": "Bardzo niezadowalające",
    "Feedback.very_satisfied": "Bardzo zadowalające",
    "FetchSlots.error": "Coś poszło nie tak, spróbuj ponownie później",
    "FindAvailableDate.earliest_available": "Najwcześniej dostępne",
    "FindAvailableDate.finding_first_time": "Znalezienie pierwszego wolnego czasu",
    "FindAvailableDate.finding_next_time": "Znalezienie następnego wolnego czasu",
    "FindAvailableDate.finding_time": "Znalezienie wolnego czasu",
    "FindAvailableDate.first_available_date": "Tak szybko, jak to możliwe",
    "FindAvailableDate.next_available": "Następny dostępny",
    "FindAvailableDate.next_available_date": "Następna dostępna data",
    "FindAvailableDate.tooltip_error": "Błąd podczas pobierania daty.",
    "FindAvailableDate.tooltip_fetching": "Pobieram datę...",
    "Form.field_error.cell_phone_characters": "Wprowadź prawidłowy numer telefonu komórkowego.",
    "Form.field_error.cell_phone_length": "Wprowadź prawidłowy numer telefonu komórkowego o długości od 9 do 20 znaków.",
    "Form.field_error.email_structure": "Proszę wpisać aktualny adres e-mail.",
    "Form.field_error_max_length": "To pole nie może przekraczać {max} znaków.",
    "Form.field_error_min_length": "To pole nie może zawierać mniej niż {min} znaków.",
    "Form.field_required": "To pole jest wymagane.",
    "Form.optional": "(opcjonalnie)",
    "GenericError.subtitle": "Nie można znaleźć strony, której szukasz",
    "GenericError.title": "Ups, coś poszło nie tak",
    "Input.additional_input": "Dodatkowa wiadomość",
    "Invitations.verification_failed": "Podany przez Ciebie adres e-mail nie pasuje do naszych akt.",
    "Label.phone_number": "Numer telefonu",
    "Landing.kiosk.appointment.subtitle": "Zaplanuj datę i godzinę wizyty",
    "Landing.kiosk.appointment.title": "Zarezerwuj wizytę",
    "Landing.kiosk.check_in.subtitle": "Rejestracja na wizytę planową",
    "Landing.kiosk.check_in.title": "Mam spotkanie",
    "Landing.kiosk.hours_temp": "Jesteśmy otwarci do godziny 17:00",
    "Landing.kiosk.no_options": "W tej chwili nie ma dostępnych opcji.",
    "Landing.kiosk.qr_code.alt": "Zeskanuj ten kod QR, aby kontynuować na swoim urządzeniu mobilnym",
    "Landing.kiosk.qr_code.manage": "aby zarządzać wszystkim ze swojego telefonu",
    "Landing.kiosk.qr_code.scan": "Przeskanuj mnie",
    "Landing.kiosk.qr_code_alt": "Zeskanuj ten kod QR, aby kontynuować na swoim urządzeniu mobilnym",
    "Landing.kiosk.speak_to_staff": "Aby uzyskać pomoc, skontaktuj się z członkiem personelu.",
    "Landing.kiosk.subtitle": "Jak możemy Ci dziś pomóc?",
    "Landing.kiosk.title": "Witaj!",
    "Landing.kiosk.touch": "Dotknij ekranu, aby rozpocząć",
    "Landing.kiosk.walk_in.subtitle": "See someone as soon as possible",
    "Landing.kiosk.walk_in.title": "Obsługa bez wcześniejszej rezerwacji",
    "LandingChoices.book_appointment_subtitle": "Aby spotkać się z kimś, wybierz określoną datę i godzinę",
    "LandingChoices.book_appointment_title": "Zarezerwuj termin spotkania",
    "LandingChoices.callback_subtitle": "Spotkaj się z kimś dzisiaj, tak szybko jak to możliwe",
    "LandingChoices.callback_title": "Dołącz do kolejki",
    "LandingChoices.check_in_subtitle": "Jeśli jesteś tu na umówione wcześniej spotkanie",
    "LandingChoices.check_in_title": "Zamelduj się",
    "LandingChoices.choose_another_location_subtitle": "Kliknij tutaj, aby wyszukać inną lokalizację",
    "LandingChoices.prefill_title": "Witaj {name}! Co chcesz{space}zrobić?",
    "LandingChoices.prefill_title.service": "Witaj {name}! Jak chcesz zarezerwować termin spotkania w sprawie {service}?",
    "LandingChoices.private_location": "Ta lokalizacja jest prywatna",
    "LandingChoices.title": "Co chcesz{space}zrobić?",
    "LandingChoices.title.service": "Jak chcesz zarezerwować termin spotkania w sprawie {service}?",
    "LandingChoices.virtual_location": "Wirtualna Lokacja",
    "LandingChoices.welcome_to": "Witaj w",
    "Languages.chinese": "chiński",
    "Languages.english": "Angielski",
    "Languages.french": "Francuski",
    "Languages.korean": "koreański",
    "Languages.language": "Język",
    "Languages.polish": "Polski",
    "Languages.portuguese": "portugalski",
    "Languages.russian": "Rosyjski",
    "Languages.spanish": "Hiszpański",
    "LobbyBanner.button": "Dołącz do kolejki",
    "LobbyBanner.button_description": "Kliknij tutaj, aby dołączyć do kolejki zamiast umawiać się na wizytę",
    "LobbyBanner.phone_button": "Zażądaj oddzwonienia",
    "LobbyBanner.phone_button_description": "Kliknij tutaj, aby zarezerwować oddzwonienie zamiast umawiania się na spotkanie",
    "LobbyBanner.title": "Termin spotkania nie jest wymagany!",
    "LobbyBanner.wait_time": "Szacowany czas oczekiwania: {time} min",
    "Location.previously_booked": "Poprzednio zarezerwowano",
    "Location.search_area": "Wyszukaj w tym obszarze",
    "Location.select_location": "Wybierz lokalizację",
    "Location.select_location_prefill": "{name}, wybierz lokalizację",
    "LocationDetails.continue": "Kontynuuj",
    "LocationDetails.location_details": "Szczegóły lokalizacji",
    "LocationDetails.see_all_locations": "Zobacz wszystkie lokalizacje",
    "LocationDetails.select_location": "Wybierz tę lokalizację",
    "LocationDirectionsButton.text": "Wskazówki dojazdu",
    "LocationHours.closed": "Zamknięte",
    "LocationHours.title": "Godziny pracy",
    "LocationIdentifier.empty_error": "Wpisz numer lokalizacji",
    "LocationIdentifier.help_text": "Zacznij od wprowadzenia numeru lokalizacji, który widzisz na znaku",
    "LocationIdentifier.location_number": "Numer lokalizacji",
    "LocationIdentifier.not_found_error": "Nie pasuje to do żadnej z naszych lokalizacji",
    "LocationIdentifier.title": "Witaj!",
    "LocationList.empty_state_message": "Nie znaleziono żadnych wyników",
    "LocationList.empty_state_secondary": "Spróbuj rozszerzyć zakres wyszukiwania",
    "LocationList.finding_locations": "Znajdujemy lokalizacje w Twoim pobliżu",
    "LocationList.near_me": "Lokalizacje w moim pobliżu",
    "LocationList.no_locations": "Nie znaleziono żadnych lokalizacji",
    "LocationList.title": "Wybierz lokalizację",
    "LocationList.toggle_list": "Pokaż listę",
    "LocationList.toggle_map": "Pokaż mapę",
    "LocationPhoneCallButton.text": "Zadzwoń pod numer {number}",
    "LocationStepTitle.preferred_location": "Jaka jest Twoja preferowana lokalizacja?",
    "LocationStepTitle.preferred_location_helper": "Dopasujemy do Ciebie personel z wybranej przez Ciebie lokalizacji.",
    "LoginWithGoogle.showing_times_that_match": "Wyświetlanie godzin, które odpowiadają Twojej dostępności",
    "LoginWithGoogle.sign_in": "Zaloguj się przez Google",
    "LoginWithGoogle.sign_in_details": "w celu wyświetlenia godzin, które odpowiadają Twojej dostępności",
    "Manage.appointment_details": "Szczegóły terminu",
    "Manage.book_another": "Zarezerwuj inny termin spotkania",
    "Manage.can_never_cancel": "Zarządzanie online jest niedostępne. Aby dokonać zmian, skontaktuj się z nami.",
    "Manage.cancel": "Anuluj spotkanie",
    "Manage.cancellation_policy": "Zasady anulowania",
    "Manage.cancellation_policy_details": "Jeśli nie możesz wziąć udziału w spotkaniu, anuluj je na {duration} przed zaplanowanym terminem.",
    "Manage.cancellation_policy_details_no_limit": "Jeśli nie możesz wziąć udziału w spotkaniu, anuluj je przed zaplanowanym terminem.",
    "Manage.cancelled": "Spotkanie zostało anulowane",
    "Manage.cancelled_successfully": "Spotkanie zostało anulowane",
    "Manage.cannot_cancel_anymore": "Zarządzanie online jest w tej chwili niedostępne. Aby dokonać zmian, skontaktuj się z nami.",
    "Manage.confirmation": "Potwierdzenie",
    "Manage.confirmation_email": "Wysłaliśmy Ci wiadomość e-mail zawierającą szczegóły dotyczące Twojego spotkania oraz wszelkie dodatkowe informacje z nim związane.",
    "Manage.details": "Szczegóły",
    "Manage.in_the_past": "Spotkanie ma termin w przeszłości",
    "Manage.reschedule": "Zmień termin",
    "Manage.restart": "Zacznij ponownie ze strony głównej",
    "Manage.successfully_booked": "Spotkanie zostało zarezerwowane",
    "Manage.updated_successfully": "Spotkanie zostało zaktualizowane",
    "ManageAppointmentDetails.details_below": "Szczegóły Twojego spotkania widnieją poniżej. Wysłaliśmy Ci kopię pocztą elektroniczną.",
    "ManageAppointmentDetails.done_title": "Gotowe!",
    "ManageAppointmentDetails.successfully_booked": "Termin spotkania został zarezerwowany.",
    "ManageAppointmentDetails.successfully_rescheduled": "Termin spotkania został zmieniony.",
    "ManageAppointmentDetails.your_appointment": "Twoje spotkanie",
    "MeetingMethodForm.appointment_date": "{date} o {time}",
    "MeetingMethodForm.keep": "Zachowaj",
    "MeetingMethodForm.message.in_person": "Zaktualizuj metodę spotkania zaplanowanego na {date}, aby spotkać się osobiście.",
    "MeetingMethodForm.message.off_site": "Zaktualizuj metodę spotkania zaplanowanego na {date}, aby spotkać się tam, gdzie chcesz.",
    "MeetingMethodForm.message.phone_call": "Zaktualizuj metodę spotkania zaplanowanego na {date}, aby odbyć je telefonicznie.",
    "MeetingMethodForm.message.video_call": "Zaktualizuj metodę spotkania zaplanowanego na {date}, aby odbyć je jako wideorozmowę.",
    "MeetingMethodForm.new_method_label": "W jaki sposób weźmiesz udział w spotkaniu?",
    "MeetingMethodForm.title": "Zaktualizuj metodę spotkania",
    "MeetingMethodForm.update_method": "Zaktualizuj metodę spotkania",
    "MeetingMethodInfoBanner.only_phone_available": "To spotkanie jest dostępne tylko jako rozmowa telefoniczna",
    "MeetingMethodInfoBanner.only_video_available": "To spotkanie jest dostępne tylko jako wideorozmowa",
    "MeetingMethods.at_location_primary": "Osobiście",
    "MeetingMethods.at_location_secondary": "Wybierz jedną z naszych lokalizacji",
    "MeetingMethods.header": "W jaki sposób weźmiesz udział w spotkaniu?",
    "MeetingMethods.off_site_primary": "Poza firmą",
    "MeetingMethods.off_site_secondary": "Spotkamy się z Tobą",
    "MeetingMethods.phone_call_primary": "Telefon",
    "MeetingMethods.phone_call_secondary": "Zadzwonimy do Ciebie",
    "MeetingMethods.video_call_primary": "Wideo",
    "MeetingMethods.video_call_secondary": "Wyślemy Ci link",
    "MonthlyDatePicker.next_month": "Następny miesiąc",
    "MonthlyDatePicker.previous_month": "Poprzedni miesiąc",
    "Navigation.aria_label.back": "Wróć do poprzedniego kroku",
    "Navigation.aria_label.call": "Lokalizacja połączenia",
    "Navigation.aria_label.card": "Wybierz {navItem}",
    "Navigation.aria_label.date": "Wybierz {date}",
    "Navigation.aria_label.directions": "Wyświetl wskazówki dojazdu",
    "Navigation.aria_label.language": "Personel mówiący w języku {language}",
    "Navigation.aria_label.language_specific": "określony personel",
    "NoLocationPreferenceButton.skip": "Pomiń",
    "NoLocationPreferenceButton.title": "Nie mam preferencji",
    "NoTimesAvailable.no_times_in_month": "Brak terminów w {month}",
    "NoTimesAvailable.no_times_in_week": "Brak terminów w tym tygodniu",
    "NoTimesAvailable.view_next_month": "Wyświetl następny miesiąc",
    "NoTimesAvailable.view_next_week": "Wyświetl następny tydzień",
    "NotAvailable.subtitle": "Obecnie nie odbywamy spotkań",
    "NotAvailable.title": "Niedostępny",
    "OtherLocationsTimeChunks.available_times_title": "Terminy dostępne w innych lokalizacjach",
    "OtherLocationsTimeChunks.no_available_times": "Brak terminów dostępnych w innych lokalizacjach",
    "OtherLocationsTimeChunks.view_available_times": "Wyświetl terminy dostępne w innych lokalizacjach ",
    "OtherLocationsTimeChunks.view_more": "Wyświetl więcej",
    "Pages.country": "Kraj",
    "Pages.staff_preference": "Preferencje dotyczące personelu",
    "Pages.supported_language_preference": "Języki",
    "Pages.timezones": "Strefy czasowe",
    "PositionErrorAlert.denied": "Nie mogliśmy uzyskać Twojej lokalizacji, ponieważ nie mamy Twojej zgody. Sprawdź ustawienia przeglądarki dla tej witryny.",
    "PositionErrorAlert.retry": "Spróbuj ponownie",
    "PositionErrorAlert.unable": "Nie mogliśmy uzyskać Twojej lokalizacji.",
    "RadioButton": "Przycisk radiowy",
    "Reschedule.appointment_details": "Szczegóły terminu",
    "Reschedule.confirm_time": "Potwierdź termin",
    "Reschedule.error": "Nie można zmienić terminu spotkania",
    "Reschedule.label": "Potwierdzenie zmiany terminu",
    "Reschedule.reschedule": "Zmień termin",
    "Security.continue": "Kontynuuj",
    "Security.email_verification": "Weryfikacja adresu e-mail",
    "Security.please_verify": "Przed rezerwacją terminu zweryfikuj swój adres e-mail.",
    "SelectInput.default": "Wybierz",
    "Service.all_categories_title": "Wszystkie kategorie",
    "Service.category_title": "Kategorie",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {# godz.} other {# godz.}}",
    "Service.minutes": "{minutes, plural, one {# min} other {# min}}",
    "Service.mobile_header": "W jakiej dziedzinie potrzebujesz pomocy?",
    "Service.no_services": "Nie znaleziono żadnych usług",
    "Service.read_less": "Przeczytaj mniej",
    "Service.read_more": "Przeczytaj więcej",
    "Service.select_title": "Wybierz usługę",
    "Service.select_title_prefill": "{name},{break}wybierz usługę",
    "Service.service_count": "{number, plural, one {# usługa} other {Usługi: #}}",
    "Service.uncategorized_title": "Inny",
    "ServiceHeader.group": "Grupa",
    "ServiceInstructions.title": "Instrukcje",
    "ShortcutExpired.message": "Ten link wygasł",
    "Sidebar.footer": "Obsługiwane przez",
    "Sidebar.title": "Zarezerwuj termin spotkania",
    "Sidebar.title_check_in": "Zameldować się",
    "Sidebar.title_join_the_line": "Dołącz do linii",
    "SpecificUserLanguagePreferences.title": "Preferencje dotyczące języka",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "Krok {current} z {end}",
    "Steps.check_in": "Zameldować się",
    "Steps.check_in_description": "<p> Zamelduj się na wizytę. <\/p> <p> Powiadomimy Cię, gdy członek personelu będzie gotowy do obsługi. <\/p>",
    "Steps.date_time": "Data i godzina",
    "Steps.date_time_no_wait_times": "Gdy tylko członek personelu będzie gotowy do obsługi",
    "Steps.date_time_with_wait_times": "Szacowany czas oczekiwania: {time} min",
    "Steps.date_time_with_zero_wait_times": "W tej chwili nie ma czasu oczekiwania",
    "Steps.details": "Szczegóły",
    "Steps.details_description": "<p> Dodaj siebie, aby być widocznym już dziś. <\/p> <p> Powiadomimy Cię o powrocie, gdy członek personelu będzie gotowy do obsługi. <\/p>",
    "Steps.location": "Lokalizacja",
    "Steps.meeting_details": "Szczegóły spotkania",
    "Steps.meeting_methods": "Metoda spotkania",
    "Steps.no_staff_warning": "Dołączając do kolejki, zostaniesz obsłużony przez pierwszego dostępnego pracownika. To może nie być {name} , którego wcześniej wybrałeś",
    "Steps.service": "Usługa",
    "Steps.services": "Usługi",
    "Steps.staff": "Personel",
    "Steps.staff_preference": "Preferencje dotyczące personelu",
    "Steps.summary": "Podsumowanie",
    "Steps.your_details": "Twoje szczegóły",
    "Summary.book_appointment": "Zarezerwuj termin",
    "Summary.header": "Prawie gotowe! Sprawdź i zarezerwuj",
    "Summary.join_the_line": "Dołącz do linii",
    "Summary.please_review": "Sprawdź",
    "SummaryExpansionPanel.title": "Streszczenie",
    "SupportedLanguagePreferencePicker.anyone": "ktokolwiek",
    "SupportedLanguagePreferencePicker.anyone_available": "ktokolwiek dostępny",
    "SupportedLanguagePreferencePicker.assigned_staff": "przypisany personel",
    "SupportedLanguagePreferencePicker.language_preference": "preferencje dotyczące języka",
    "SupportedLanguagePreferencePicker.specific_language": "ktokolwiek, kto mówi w języku {language}",
    "Svg.alt_text.appointment": "Spotkanie",
    "Svg.alt_text.back": "Wróć",
    "Svg.alt_text.back_chevron": "Wróć",
    "Svg.alt_text.checkbox_not_selected": "Pole wyboru nie jest zaznaczone",
    "Svg.alt_text.checkbox_selected": "Zaznaczono element pola wyboru",
    "Svg.alt_text.checkmark": "Znacznik wyboru",
    "Svg.alt_text.clock": "Zegar",
    "Svg.alt_text.coconut_logo": "Logotyp firmy Coconut Software",
    "Svg.alt_text.completed": "Ukończono",
    "Svg.alt_text.continue": "Kontynuuj",
    "Svg.alt_text.country_selected": "Wybrany kraj",
    "Svg.alt_text.down_chevron": "Pokaż więcej",
    "Svg.alt_text.drop_down_arrow": "Strzałka listy rozwijanej",
    "Svg.alt_text.edit": "Edytuj",
    "Svg.alt_text.edit_timezone": "Zmień strefę czasową",
    "Svg.alt_text.expand": "Rozwiń",
    "Svg.alt_text.expanded": "Rozwinięto",
    "Svg.alt_text.forward_chevron": "Przekaż",
    "Svg.alt_text.hide_location_details": "Ukryj szczegóły lokalizacji",
    "Svg.alt_text.in_person": "W lokalizacji",
    "Svg.alt_text.item_selected": "Wybrano element",
    "Svg.alt_text.language": "Język",
    "Svg.alt_text.location_pin": "Lokalizacja",
    "Svg.alt_text.near_me": "Blisko mnie",
    "Svg.alt_text.next": "Następny",
    "Svg.alt_text.next_chevron": "Następny",
    "Svg.alt_text.next_month": "Następny miesiąc",
    "Svg.alt_text.next_week": "Następny tydzień",
    "Svg.alt_text.person": "Osoba",
    "Svg.alt_text.phone": "Telefon",
    "Svg.alt_text.previous_month": "Poprzedni miesiąc",
    "Svg.alt_text.previous_week": "Poprzedni tydzień",
    "Svg.alt_text.search": "Wyszukaj",
    "Svg.alt_text.select_category": "Wybierz kategorię",
    "Svg.alt_text.select_language": "Wybierz język",
    "Svg.alt_text.show_location_details": "Pokaż szczegóły lokalizacji",
    "Svg.alt_text.timezone": "Strefa czasowa",
    "Svg.alt_text.timezone_selected": "Wybrano strefę czasową",
    "Svg.alt_text.up_chevron": "Pokaż mniej",
    "Svg.alt_text.upload": "Prześlij",
    "Svg.alt_text.user_selected": "Wybrano użytkownika",
    "Svg.alt_text.video": "Wideo",
    "Svg.alt_text.walk_in": "Ikona usługi wizyty",
    "TimeChunk.no_spots": "Nie pozostały żadne miejsca",
    "TimeChunk.spots_remaining": "{number, plural, one {# miejsce pozostało} other {Pozostałe miejsca: #}}",
    "TimeChunk.unlimited_spots": "Miejsca bez ograniczeń",
    "TimeChunks.afternoon": "Popołudnie",
    "TimeChunks.evening": "Wieczór",
    "TimeChunks.morning": "Ranek",
    "TimeChunks.no_available_times": "Brak dostępnych terminów",
    "TimeChunks.no_available_times_in_month": "Nie znaleziono wolnych miejsc w tym miesiącu.",
    "TimeChunks.no_available_times_in_range": "Nie znaleziono dostępności w okresie {range} {rangeUnit}.",
    "TimeChunks.no_available_times_in_range_month": "Nie znaleziono dostępności w okresie {range} miesiąca.",
    "TimeChunks.no_available_times_in_range_week": "Nie znaleziono dostępności w ciągu {range} tygodnia.",
    "TimeChunks.no_available_times_in_week": "Brak dostępności w tym tygodniu.",
    "TimeChunks.no_available_times_on": "Brak dostępnych terminów w dniu {date}",
    "TimeChunks.select_another_day": "Wybierz inny dzień, aby znaleźć dostępne terminy",
    "TimeChunks.select_another_month": "Spróbuj jeszcze miesiąc.",
    "TimeChunks.select_another_range": "Wypróbuj innego pracownika lub lokalizację.",
    "TimeChunks.select_another_week": "Spróbuj jeszcze tydzień.",
    "TimeChunks.select_user": "Wybierz członka personelu, aby sprawdzić jego dostępność",
    "TimezoneSelectInput.label": "Region strefy czasowej",
    "TimezoneSelectInput.required": "Strefa czasowa jest wymagana.",
    "TimezonesShownIn.label": "Wyświetlane są terminy w",
    "TooLateToCancelModal.message": "Nie możesz już anulować tego spotkania online.",
    "TooLateToCancelModal.title": "Zbyt późno, aby anulować",
    "Ui.archive": "Archiwizuj",
    "Ui.back": "Wróć",
    "Ui.back_to_previous": "Wróć do: {previous}",
    "Ui.cancel": "Anuluj",
    "Ui.clear": "Wyczyść",
    "Ui.close": "Zamknij",
    "Ui.confirm": "Potwierdź",
    "Ui.continue": "Kontynuuj",
    "Ui.current_selection": "Bieżący wybór",
    "Ui.details_about_name": "Szczegóły na temat {name}",
    "Ui.details_about_name_and_address": "Szczegóły na temat {name} w {address}",
    "Ui.dismiss": "Odrzuć",
    "Ui.do_not_continue": "Kontynuuj",
    "Ui.hours_of_operation": "Godziny operacji",
    "Ui.near_me": "Blisko mnie",
    "Ui.next": "Dalej",
    "Ui.next_to_previous": "Dalej do: {next}",
    "Ui.ok": "OK",
    "Ui.search": "Wyszukaj",
    "Ui.search.instructions": "Proszę zacząć pisać",
    "Ui.search.no_results_found": "Nie znaleziono żadnych wyników",
    "Ui.search.plain": "Szukaj",
    "Ui.select": "Wybierz",
    "Ui.select_name": "Wybierz {name}",
    "Ui.selected": "Wybrano",
    "Ui.sign_out": "Wyloguj się",
    "Ui.type_here": "Wpisz tutaj...",
    "Ui.undo": "Cofnij",
    "UserPreference.anyone": "Ktokolwiek",
    "UserPreference.anyone_available": "Ktokolwiek dostępny",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "Przypisz mi tylko kogoś, kto mówi w języku {language}",
    "UserPreference.assign_me_title": "Ktokolwiek, kto mówi w języku {language}",
    "UserPreference.assigned_title": "Przypisany personel",
    "UserPreference.header": "Czy masz preferowanego członka personelu?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "Bez preferencji",
    "UserPreference.random_subtitle": "Nie mam preferencji",
    "UserPreference.random_title": "Ktokolwiek dostępny",
    "UserPreference.see_all_staff": "Zobacz cały personel",
    "UserPreference.select.header": "Wybierz preferowanego członka personelu",
    "UserPreference.specific_subtitle": "Wiem dokładnie, z kim chcę się spotkać",
    "UserPreference.specific_title": "Określony członek personelu",
    "Validator.address": "adres",
    "Validator.cell_phone": "numer telefonu komórkowego",
    "Validator.city": "miasto",
    "Validator.country": "kraj",
    "Validator.email": "e-mail",
    "Validator.first_name": "imię",
    "Validator.home_phone": "telefon domowy",
    "Validator.last_name": "nazwisko",
    "Validator.meeting_method": "metoda spotkania",
    "Validator.not_8_consecutive_digits": "Nie może zawierać 8 lub więcej kolejnych cyfr",
    "Validator.not_url": "Nie może zawierać adresu URL.",
    "Validator.notes": "uwagi",
    "Validator.phone_code": "Nieprawidłowy kod kraju",
    "Validator.phone_country_code": "Podaj 1-3-cyfrowy kod kraju poprzedzony znakiem „+”, a następnie numer telefonu.",
    "Validator.phone_length": "Nieprawidłowa długość telefonu",
    "Validator.phone_valid": "Podaj prawidłowy numer telefonu.",
    "Validator.postal_code": "kod pocztowy",
    "Validator.province": "prowincja",
    "Validator.state": "stan",
    "Validator.work_phone": "telefon służbowy",
    "Validator.zip_code": "kod pocztowy",
    "ViewOnMapButton.title": "Wyświetl na mapie",
    "WaitTime.loading": "Pobieranie czasu oczekiwania na wizytę...",
    "WaitTime.no_walkins_currently": "Obecnie nie przyjmujemy wizyt",
    "WaitTime.wait": "Bieżący czas oczekiwania na wizytę: {wait}",
    "WaitTime.wait_time": "Bieżący czas oczekiwania na wizytę: {waitTime}",
    "WeeklyDatePicker.next_week": "Następny tydzień",
    "WeeklyDatePicker.previous_week": "Poprzedni tydzień",
    "WhatNow.homepage": "Przejdź do witryny internetowej {vendor}",
    "WhatNow.title": "Co teraz?"
}